'use client';
import { captureException, captureMessage } from '@sentry/nextjs';

export type MessageArg = string | number | null | Error | unknown;

export type Level = 'debug' | 'info' | 'warn' | 'error';

export type LogFeature = 'network_traffic' | 'auth_status';

export default class Logger {
    prefix: string;
    feature: LogFeature | null = null;

    constructor({ prefix, feature }: { prefix: string; feature?: LogFeature | null }) {
        this.prefix = prefix;
        this.feature = feature ?? null;
    }

    static make(prefix: string, feature: LogFeature | null = null): Logger {
        return new Logger({ prefix, feature });
    }

    with(feature: LogFeature): Logger {
        return Logger.make(this.prefix, feature);
    }

    isEnabled(_level: Level): boolean {
        // return LevelMap[this.level] <= LevelMap[level] && this.featureEnabled();
        return true;
    }

    static networkEnabled(): boolean {
        return false;
    }

    makePrefix() {
        const parts: unknown[] = [];
        parts.push(new Date().toISOString());
        if (this.prefix) {
            parts.push(`[${this.prefix}]`);
        }
        return `${parts.join(' ')}`;
    }

    debug(message?: MessageArg, ...data: unknown[]) {
        console.debug.apply(console, [this.makePrefix(), message, ...data]);
    }

    log(message?: MessageArg, ...data: unknown[]) {
        this.info(message, data?.length > 0 ? data : undefined);
    }

    info(message?: MessageArg, ...data: unknown[]) {
        console.info.apply(console, [this.makePrefix(), message, ...data]);
    }

    warn(message?: MessageArg, ...data: unknown[]) {
        captureMessage(`${message}`, 'warning');
        data?.forEach((d) => {
            if (d instanceof Error) {
                captureException(d, { level: 'warning' });
            }
        });

        console.warn.apply(console, [this.makePrefix(), message, ...data]);
    }

    error(message?: MessageArg, ...data: unknown[]) {
        if (message instanceof Error) {
            captureException(message);
        }
        data?.forEach((d) => {
            if (d instanceof Error) {
                captureException(d);
            }
        });

        if (!this.isEnabled('error')) {
            return;
        }

        console.error.apply(console, [this.makePrefix(), message, ...data]);
    }
}
