import { API } from '@/config/Config';

import { removeLeadingSlash } from '@/util/url-util';

export const makePath = (path: string, secured = false) => {
    const prefix = secured ? API.SECURE_URL_PART : API.PUBLIC_URL_PART;
    const _path = removeLeadingSlash(path);
    return `${prefix}${_path}`;
};

export const securePath = (path: string) => makePath(path, true);
export const publicPath = (path: string) => makePath(path, false);

const Endpoint = {
    appConfig: publicPath('/app-config'),
    stripe: {
        setupIntent: publicPath('/stripe/setupintent'),
    },

    switchExperience: publicPath('/switch-experience'),
    validate: {
        phone: (input: string) => makePath(`/validate/phone/${input}`),
    },
    recipes: {
        base: securePath('/recipes'),
    },
    coupons: {
        apply: securePath('/coupons/apply'),
    },
    recipe: (id: number | string) => ({
        publicRecipe: publicPath(`/recipes/${id}`),
        base: securePath(`/recipes/${id}`),
        menus: securePath(`/recipes/${id}/menus`),
        tags: securePath(`/recipes/${id}/tags`),
        tag: (tagId: number) => securePath(`/recipes/${id}/tags/${tagId}`),
        reviews: securePath(`/recipes/${id}/reviews`),
        publicReviews: publicPath(`/recipes/${id}/reviews`),
        galleyRecipe: publicPath(`/recipes/${id}/galley-recipe`),
    }),
    subscribers: securePath('/subscribers'),
    me: securePath('/me'),
    subscriber: (subscriberId: number | string) => ({
        base: securePath(`/subscribers/${subscriberId}`),
        giftCard: (code: string) => ({
            claim: securePath(`/subscribers/${subscriberId}/gift-cards/${code}/claim`),
        }),
        giftCards: {
            available: securePath(`/subscribers/${subscriberId}/gift-cards/available`),
            claimed: securePath(`/subscribers/${subscriberId}/gift-cards/claimed`),
            purchased: securePath(`/subscribers/${subscriberId}/gift-cards/purchased`),
        },
        menuOrder: ({ menuId }: { menuId: number }) => securePath(`/orders/user/${subscriberId}/menu/${menuId}`),
        orders: securePath(`/subscribers/${subscriberId}/orders`),
        paymentMethodsCard: securePath(`/subscribers/${subscriberId}/payment_methods/card`),
        paymentMethodsLink: securePath(`/subscribers/${subscriberId}/payment_methods/link`),
        paymentMethodsAll: securePath(`/subscribers/${subscriberId}/payment_methods`),
        referralCode: () => securePath(`/subscribers/${subscriberId}/referral-code`),
        referralRewards: () => securePath(`/subscribers/${subscriberId}/referral-rewards`),
        signupReferralCode: () => securePath(`/subscribers/${subscriberId}/signup-referral-code`),
        referralCodeClaim: (code: string) => securePath(`/subscribers/${subscriberId}/referral-codes/${code}/claim`),
        referralCodes: () => securePath(`/subscribers/${subscriberId}/referral-codes`),
        pause: () => securePath(`/subscribers/${subscriberId}/pause`),
        schedule: securePath(`/subscribers/${subscriberId}/schedule`),
        dashboard: securePath(`/subscribers/${subscriberId}/dashboard`),
        reviews: securePath(`/subscribers/${subscriberId}/reviews`),
        notificationPreferences: securePath(`/subscribers/${subscriberId}/notification-preferences`),
        notificationPreference: (type_id: number) =>
            securePath(`/subscribers/${subscriberId}/notification-preferences/${type_id}`),
    }),
    pricing_tables: '/pricing_tables',
    pricing_tables_secured: securePath('/pricing_tables'),
    menus: {
        base: securePath('/menus'),
        all: securePath('/menus/all'),
        active: '/menus/active',
        current: '/menus/current',
    },
    menu: (menuId: number | string) => ({
        base: securePath(`/menus/${menuId}`),
        totals: securePath(`/menus/${menuId}/totals`),
        recalculate_billing: securePath(`/menus/${menuId}/recalculate-billing`),
        recipes: securePath(`/menus/${menuId}/recipes`),
        default_day_overrides: securePath(`/menus/${menuId}/default-day-overrides`),
        default_recipe_pricing: securePath(`/menus/${menuId}/default-recipe-pricing`),
        notification_copy: securePath(`/menus/${menuId}/notification-copy`),
        orders: securePath(`/menus/${menuId}/orders`),
        deliveryProjects: securePath(`/menus/${menuId}/delivery-projects`),
        resetRoutes: securePath(`/menus/${menuId}/reset-routes`),
        recipe: (recipeId: number) => securePath(`/menus/${menuId}/recipes/${recipeId}`),
        deliveryDayWindows: securePath(`/menus/${menuId}/delivery-day-windows`),
        downloadDriverRoute: ({ projectId, driverId }: { projectId: string | number; driverId: string }) =>
            securePath(`/menus/${menuId}/delivery-projects/${projectId}/drivers/${driverId}/download`),
    }),
    // orders: {
    //     base: securePath('/orders'),
    // },
    order: (orderId) => ({
        base: securePath(`/orders/${orderId}`),
        transactions: securePath(`/orders/${orderId}/transactions`),
    }),
    tags: {
        base: securePath('/tags'),
    },
    tag: (tagId: number) => ({
        base: securePath(`/tags/${tagId}`),
    }),
    tagCategories: {
        base: securePath('/tag-categories'),
    },
    tagCategory: (categoryId: number) => ({
        base: securePath(`/tag-categories/${categoryId}`),
    }),
    deliveryDayWindows: {
        base: securePath('/delivery-day-windows'),
    },
    giftCards: {
        base: securePath('/gift-cards'),
    },
    giftCard: (code: string) => ({
        base: securePath(`/gift-cards/${code}`),
    }),
    referralPrograms: () => ({
        current: securePath('/referral-programs/current'),
    }),
    publicReferralCode: (code: string) => publicPath(`/referral-codes/${code}`),
    admin: {
        coupons: {
            base: securePath('/admin/coupons'),
        },
        tasks: {
            general: securePath('/admin/tasks/general'),
            billing: securePath('/admin/tasks/billing'),
        },
        scheduledSms: {
            base: securePath('/admin/scheduled-sms'),
            forId: (id: string | number) => securePath(`/admin/scheduled-sms/${id}`),
        },
        dashboard: {
            announcements: securePath('/dashboard/announcements'),
            announcement: (id: number | string) => securePath(`/dashboard/announcements/${id}`),
            announcementsUpcoming: securePath('/dashboard/announcements/upcoming'),
            promotions: securePath('/dashboard/promotions'),
            promotion: (id: number | string) => securePath(`/dashboard/promotions/${id}`),
            promotionsUpcoming: securePath('/dashboard/promotions/upcoming'),
        },
    },
    galley: {
        recipeSearch: securePath(`/galley/recipes-search`),
        recipe: (id: string) => securePath(`/galley/recipes/${id}`),
    },
};

export default Endpoint;
