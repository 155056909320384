import { useAuth0 } from '@auth0/auth0-react';
import { API, AUTH0 } from '@/config/Config';
import { isDefined } from '@/util/TypeGuards';
import qs from 'query-string';
import Logger from '@/util/Logger';
import { appendQueryParams, ensureLeadingSlash, makeQueryString, removeTrailingSlash } from '@/util/url-util';

const logger = Logger.make('useApi');

export type RequestBody = unknown;
export type ResponseBody = unknown;
export type QueryParams = Record<string, unknown>;
export type Paginated<T> = { count: number; data: T[] };
/** The default pagination returned by Objection queries */
export type ObjectionPage<T> = { total: number; results: T[] };
export const apiBaseUrl = () => `${API.API_HOST}${API.BASE_URL}`;

export class ApiError<BODY = ResponseBody> extends Error {
    response: Response;
    body?: BODY | null;

    constructor(message: string, response: Response, body?: BODY | null) {
        super(message);
        this.response = response;
        this.body = body;
    }

    getResponseMessage(): string | null {
        return ApiError.getMessage(this);
    }

    async getResponseJson<T>(): Promise<T | null> {
        if (!this.response || this.response.headers.get('content-length') === '0') {
            return null;
        }
        try {
            return await this.response.json();
        } catch (error) {
            return null;
        }
    }

    static isApiError<B = ResponseBody>(error: Error | unknown): error is ApiError<B> {
        return !!(error as ApiError).response;
    }

    static getMessage(error: Error | unknown): string | null {
        if (!ApiError.isApiError<{ message: string }>(error)) {
            return (error as Error).message;
        }
        return error.body?.message ?? error.message;
    }
}

/**
 * Create query parameters with special processing for well-known objects.
 * Note: this will ignore null or undefined values. If you must pass a query param with no value, use an empty string or something similar
 * @param {(Partial<QueryParams>) | null} params
 * @return {QueryParams | null}
 */
export const processQueryParams = (params?: Partial<QueryParams> | null): QueryParams | null => {
    if (!params) {
        return null;
    }
    // const { ownership, pagination, filters, offset, limit, search, ...rest } = params ?? {}
    const query = { ...params };

    Object.keys(query).forEach((key) => {
        const value = query[key];
        if (!isDefined(value)) {
            delete query[key];
        }
    });

    if (Object.keys(query).length) {
        return query;
    }
    return null;
};

export const buildHeaders = (params: {
    access_token?: string | null;
    contentType?: string;
}): Record<string, string> => {
    const { access_token } = params;
    const headers: Record<string, string> = {
        Accept: params.contentType ?? 'application/json',
        'Content-Type': params.contentType ?? 'application/json',
    };
    if (access_token) {
        headers.Authorization = `Bearer ${access_token}`;
    }

    return headers;
};

export const makeUrl = (baseUrl: string, path: string, query?: QueryParams | null): string => {
    const host = removeTrailingSlash(baseUrl);
    const [urlPath, querySearch] = path.split('?');
    const paths = ensureLeadingSlash(urlPath);
    const url = new URL(`${host}${paths}`);
    // const search = appendQueryParams(querySearch ?? '', query)

    let aggregateQuery = { ...query };

    if (querySearch) {
        const pathQuery = qs.parse(querySearch);
        aggregateQuery = { ...aggregateQuery, ...pathQuery };
    }

    if (aggregateQuery) {
        url.search = makeQueryString(aggregateQuery);
    }

    return url.toString();
};
export type AuthenticatedFetchOptions = {
    contentType?: string;
    plainText?: boolean;
    rawBody?: boolean;
    blob?: boolean;
};
const useApi = () => {
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();

    const getAccessToken = async (): Promise<string | null> => {
        try {
            if (isAuthenticated) {
                const accessToken = await getAccessTokenSilently({ authorizationParams: { audience: AUTH0.AUDIENCE } });
                return accessToken ?? null;
            } else {
                return null;
            }
        } catch (error) {
            logger.error('[useApi] failed to get access token...', error);
        }
        return null;
    };

    /**
     * fetch response. Will throw error on non-200 status codes
     * @param {string} path
     * @param {Partial<RequestInit>} params
     * @param options
     * @throws Error
     * @return {Promise<any>}
     */
    const authenticatedFetch = async <R>(
        path: string,
        params?: Partial<RequestInit>,
        options?: AuthenticatedFetchOptions
    ): Promise<R> => {
        const url = makeUrl(apiBaseUrl(), path);
        const access_token = await getAccessToken();
        const response = await fetch(url, {
            ...(params ?? {}),
            headers: buildHeaders({ access_token, contentType: options?.contentType }),
        });

        let responseBody: R | typeof response.body | string | undefined | Blob;
        try {
            const responseContentLength = response.headers.get('content-length')
                ? Number(response.headers.get('content-length'))
                : undefined;
            if (response.body && responseContentLength !== 0) {
                if (options?.rawBody) {
                    responseBody = response.body;
                } else if (options?.blob) {
                    responseBody = await response.blob();
                } else if (
                    isDefined(options?.contentType) &&
                    (options?.contentType === 'text/csv' || options?.plainText)
                ) {
                    responseBody = response.status !== 204 ? await response.text() : undefined;
                } else {
                    responseBody = response.status !== 204 ? await response.json() : undefined;
                }
            } else if (response.ok && !responseContentLength) {
                responseBody = null;
            }
        } catch (error) {
            logger.error(`Error parsing response body: ${url}`, error);
            throw error;
        }

        if (!response.ok) {
            const errorBody = responseBody as { message?: string; error?: string } | null;
            const message =
                errorBody?.message ?? errorBody?.error ?? (errorBody as string | null) ?? response.statusText;
            throw new ApiError(message, response, responseBody);
        }
        return responseBody as R;
    };

    const serializeBody = (body: RequestBody) => {
        return JSON.stringify(body);
    };

    const get = <R>(url: string, queryParams?: QueryParams | null, options?: AuthenticatedFetchOptions) => {
        return authenticatedFetch<R>(appendQueryParams(url, queryParams), { method: 'GET' }, options);
    };

    const put = <R = ResponseBody, B = RequestBody>(url: string, body: B) => {
        return authenticatedFetch<R>(url, { method: 'PUT', body: serializeBody(body) });
    };

    const patch = <R = ResponseBody, B = RequestBody>(url: string, body: B) => {
        return authenticatedFetch<R>(url, { method: 'PATCH', body: serializeBody(body) });
    };

    const post = <R = ResponseBody, B = RequestBody>(url: string, body: B) => {
        return authenticatedFetch<R>(url, { method: 'POST', body: serializeBody(body) });
    };

    const doDelete = <R = ResponseBody, B = RequestBody>(url: string, body?: B) => {
        return authenticatedFetch<R>(url, { method: 'DELETE', body: body ? serializeBody(body) : undefined });
    };

    return { authenticatedFetch, getAccessToken, get, put, post, patch, delete: doDelete };
};

export default useApi;
