import { removeTrailingSlash } from '@/util/url-util';

export enum FileType {
    json = 'json',
    tsv = 'tsv',
    csv = 'csv',
}

export const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

export function getLetterForIndex(index: number): string {
    const i = index % 26;
    return alphabet[i];
}

export const getCurrencyFormatter = (maximumFractionDigits?: number) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits, // (causes 2500.99 to be printed as $2,501)
    });
};

export function formatDollars(dollars: number): string {
    return getCurrencyFormatter().format(dollars);
}

/**
 *
 * @param {number} cents
 * @param {boolean} roundWhole if the number is a whole dollar, don't return the cents
 * @return {string}
 */
export function formatCents(cents: number, maximumFractionDigits = 0): string {
    if (cents % 100 === 0) {
        return getCurrencyFormatter(maximumFractionDigits).format(cents / 100);
    }
    return getCurrencyFormatter().format(cents / 100);
}

export function splitName(name?: string | null): { firstName?: string | null; lastName?: string | null } {
    const parts = name?.trim().split(' +') ?? [];
    return { firstName: parts[0] ?? null, lastName: parts[1] ?? null };
}

export const pluralizeKits = (kits: number) => {
    return pluralize(kits, 'kits', 'kit');
};

export const pluralizeServings = (servings: number) => {
    return pluralize(servings, 'servings', 'serving');
};

export const pluralize = (count: number, plural: string, single: string) => {
    if (count === 1) {
        return `${count} ${single}`;
    }
    return `${count} ${plural}`;
};

export const getStateCodes = () => [
    'AL',
    'AK',
    'AS',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FM',
    'FL',
    'GA',
    'GU',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MH',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'MP',
    'OH',
    'OK',
    'OR',
    'PW',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VI',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
];

export const getStateLabels = (): { fullName: string; shortCode: string }[] => {
    return [
        { fullName: 'Alabama', shortCode: 'AL' },
        { fullName: 'Alaska', shortCode: 'AK' },
        { fullName: 'American Samoa', shortCode: 'AS' },
        { fullName: 'Arizona', shortCode: 'AZ' },
        { fullName: 'Arkansas', shortCode: 'AR' },
        { fullName: 'California', shortCode: 'CA' },
        { fullName: 'Colorado', shortCode: 'CO' },
        { fullName: 'Connecticut', shortCode: 'CT' },
        { fullName: 'Delaware', shortCode: 'DE' },
        { fullName: 'District of Columbia', shortCode: 'DC' },
        { fullName: 'States of Micronesia', shortCode: 'FM' },
        { fullName: 'Florida', shortCode: 'FL' },
        { fullName: 'Georgia', shortCode: 'GA' },
        { fullName: 'Guam', shortCode: 'GU' },
        { fullName: 'Hawaii', shortCode: 'HI' },
        { fullName: 'Idaho', shortCode: 'ID' },
        { fullName: 'Illinois', shortCode: 'IL' },
        { fullName: 'Indiana', shortCode: 'IN' },
        { fullName: 'Iowa', shortCode: 'IA' },
        { fullName: 'Kansas', shortCode: 'KS' },
        { fullName: 'Kentucky', shortCode: 'KY' },
        { fullName: 'Louisiana', shortCode: 'LA' },
        { fullName: 'Maine', shortCode: 'ME' },
        { fullName: 'Marshall Islands', shortCode: 'MH' },
        { fullName: 'Maryland', shortCode: 'MD' },
        { fullName: 'Massachusetts', shortCode: 'MA' },
        { fullName: 'Michigan', shortCode: 'MI' },
        { fullName: 'Minnesota', shortCode: 'MN' },
        { fullName: 'Mississippi', shortCode: 'MS' },
        { fullName: 'Missouri', shortCode: 'MO' },
        { fullName: 'Montana', shortCode: 'MT' },
        { fullName: 'Nebraska', shortCode: 'NE' },
        { fullName: 'Nevada', shortCode: 'NV' },
        { fullName: 'New Hampshire', shortCode: 'NH' },
        { fullName: 'New Jersey', shortCode: 'NJ' },
        { fullName: 'New Mexico', shortCode: 'NM' },
        { fullName: 'New York', shortCode: 'NY' },
        { fullName: 'North Carolina', shortCode: 'NC' },
        { fullName: 'North Dakota', shortCode: 'ND' },
        { fullName: 'Northern Mariana Islands', shortCode: 'MP' },
        { fullName: 'Ohio', shortCode: 'OH' },
        { fullName: 'Oklahoma', shortCode: 'OK' },
        { fullName: 'Oregon', shortCode: 'OR' },
        { fullName: 'Pennsylvania', shortCode: 'PA' },
        { fullName: 'Puerto Rico', shortCode: 'PR' },
        { fullName: 'Rhode Island', shortCode: 'RI' },
        { fullName: 'South Carolina', shortCode: 'SC' },
        { fullName: 'South Dakota', shortCode: 'SD' },
        { fullName: 'Tennessee', shortCode: 'TN' },
        { fullName: 'Texas', shortCode: 'TX' },
        { fullName: 'Utah', shortCode: 'UT' },
        { fullName: 'Vermont', shortCode: 'VT' },
        { fullName: 'Virgin Islands', shortCode: 'VI' },
        { fullName: 'Virginia', shortCode: 'VA' },
        { fullName: 'Washington', shortCode: 'WA' },
        { fullName: 'West Virginia', shortCode: 'WV' },
        { fullName: 'Wisconsin', shortCode: 'WI' },
        { fullName: 'Wyoming', shortCode: 'WY' },
    ];
};

export const getSizeImageUrl = (input: string | null | undefined, size: number): string | null => {
    if (!input) {
        return null;
    }
    let imageUrl = input;
    if (imageUrl?.includes('ucarecdn.com')) {
        const [base, query] = imageUrl?.split('?');
        imageUrl = `${removeTrailingSlash(base)}/-/resize/${size}x/`;
        if (query) {
            imageUrl = `${imageUrl}?${query}`;
        }
    }
    return imageUrl ?? null;
};

export const singularUnit = (input: string | undefined | null) => {
    switch (input?.toLowerCase() ?? '') {
        case 'servings':
            return 'serving';
        case 'kits':
            return 'kit';
        case 'people':
            return 'person';
        case 'salads':
            return 'salad';
        case 'trays':
            return 'tray';
        case 'pizzas':
            return 'pizza';
        case 'boxes':
            return 'box';
        case 'packs':
            return 'pack';
        default:
            return input;
    }
};

export function getLocationOrigin() {
    const origin = typeof window !== 'undefined' && window.location.origin ? window.location.origin : '';
    return origin;
}

export function makeSlug(input: string, maxCharacters: number = 50) {
    return input
        .toLowerCase()
        .trim()
        .replaceAll(/[^a-zA-Z0-9-_]/g, ' ')
        .trim()
        .replaceAll(/\s+/g, '-')
        .substring(0, maxCharacters);
}
