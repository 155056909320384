export const isDefined = <T>(input?: T | null | undefined): input is T => {
    if (input === undefined || input === null) {
        return false;
    }
    return true;
};

export type NonNullableFields<T> = {
    [P in keyof T]: NonNullable<T[P]>;
};

export const isString = (input?: unknown | string): input is string => {
    return typeof input === 'string';
};

export type NonNullableField<T, K extends keyof T> = T & NonNullableFields<Pick<T, K>>;
